<template>
  <Breadcrumb></Breadcrumb>
  <div class="container my-4">
    <div class="row flex-column-reverse flex-md-row">
      <div class="col-md-8">
        <GoogleSearch />
      </div>
      <div class="col-md-4 order-sm-1">
        <div class="sidebar">
          <h2 class="sidebar__title">UGA People Search</h2>
          <p class="sidebar__sub">Looking for a specific person at UGA?</p>
          <p>
            People Search is UGA’s online directory for faculty, staff, and
            students. Access to People Search is limited to the UGA campus
            network or authenticated users with UGA Single Sign-On.
          </p>
          <Btn
            href="https://peoplesearch.uga.edu/"
            color="black"
            :outline="true"
            size="sm"
            >People Search</Btn
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import GoogleSearch from '@/components/GoogleSearch.vue';
import Breadcrumb from '@/components/Breadcrumb.vue';
import Btn from '@/components/Btn.vue';

export default {
  name: 'Search Results',
  metaInfo() {
    return {
      // title: `Search Results for ${searchQuery}`,
      title: `Search Results | University of Georgia`,
      meta: [{ name: 'robots', content: 'noindex' }],
    };
  },
  data() {
    return {
      searchQuery: this.$route.query.q,
    };
  },
  components: {
    GoogleSearch,
    Breadcrumb,
    Btn,
  },
  computed: {},
};
</script>

<style scoped lang="scss">
.sidebar {
  @include media-breakpoint-down(md) {
    //1em used to match padding of google's search results
    padding: 0 1em 2rem 1em;
  }
  &__title {
    font-family: $font-family-block;
    margin-bottom: 1.5rem;
    color: $red;
  }
  &__sub {
    font-weight: bold;
    font-size: 1.2rem;
    margin-bottom: 0.8rem;
  }
}
</style>
