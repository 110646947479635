<template>
  <div class="search">
    <!-- <form
      class="search__form"
      autocomplete="off"
      @submit.prevent="submitSearch"
    >
      <div class="search__form-left">
        <i id="bi-search-input" class="bi bi-search search__input-icon"></i>
        <input
          class="search__input"
          type="text"
          placeholder="Search"
          id="search"
          v-model="searchQuery"
        />
      </div>
      <div class="search__form-right">
        <Btn>Search</Btn>
      </div>
    </form> -->
    <div class="gcse-search" style="position: relative;"></div>
  </div>
</template>

<script>
// import Btn from '@/components/Btn.vue';
export default {
  name: 'Search',
  data() {
    return {
      searchRes: {},
      searchQuery: '',
    };
  },
  props: {},
  components: {
    // Btn,
  },

  mounted() {
    const plugin = document.createElement('script');
    plugin.setAttribute(
      'src',
      'https://cse.google.com/cse.js?cx=012234864659315907956:fx9b80mczsk'
    );
    plugin.async = true;
    document.head.appendChild(plugin);

    this.setSearchData();
  },
  methods: {
    setSearchData() {
      this.searchQuery = this.$route.query.q;
    },
    // submitSearch() {
    //   let cx = '012234864659315907956:fx9b80mczsk'
    //   let key = '';
    //   this.axios
    //     .get(
    //       'https://customsearch.googleapis.com/customsearch/v1?' +
    //         'cx=' +
    //         cx +
    //         '&q=' +
    //         this.searchQuery +
    //         '&key=' +
    //         key
    //     )
    //     .then((response) => {
    //       this.searchRes = response.data.items;
    //     });
    // },
  },
  computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.gcsc-branding {
  &-clickable {}
  &-img-noclear {}
}
.gcsc {
  &-find-more-on-google {
    &-branding {

    }
    &-magnifier {

    }
    &-query {

    }
    &-text {

    }
  }
  &-more-maybe-branding-root {
    font-family: $font-family-serif;
  }
}
.gs {
  &-per-result-labels {}
  &-richsnippet-box {}
  &-snippet {
    font-size: 1rem;
    &.gs-bidi-start-align {}
    b {
      font-weight: 700;
    }
  }
  &-spacer {}
  &-watermark {}
  &-webResult {
    &.gs-result {
      display: flex;
      flex-direction: column;
      div.gs-title {
        margin-bottom: 0.25rem;
      }
      .gs-title {
        font-family: $font-family-block;
        font-weight: $font-weight-normal;
        line-height: 1.1;
        letter-spacing: 0.01em;
        color: $link-color;
        order: 1;
        &:link {
          color: $link-color;
        }
        > * {
          font-size: 1.5rem !important;
          line-height: 1.5;
        }
      }
    }
    .gs-visibleUrl {
      font-family: $font-family-serif;
      color: $link-color;
      &.gs-visibleUrl-short {}
      &.gs-visibleUrl-long {}
      &.gs-visibleUrl-breadcrumb {
        color: $gray-600;
      }
    }
  }
}

.gsc-wrapper {
  .gsc-above-wrapper-area {
    // border-bottom: none !important;
    &-container {}
  }
  .gsc-adBlockNoHeight {}
  .gsc-clear-button {}
  .gsc-control {
    &-cse {
      .gsc-control-wrapper-cse {
        font-family: $font-family-sans-serif;
        padding: 0;
        font-size: 1rem;
        line-height: 1.2;
        &-en {}

      }
    }
  }
  
  .gsc-expansionArea {
    margin-top: 1rem;
    margin-bottom: 3rem;
  }
  .gsc-inline-block {}
  .gsc-input {
    &-box {
      border: 0 !important;
      border-bottom: 1px solid $black !important;
      border-radius: 0 !important;
      position: relative;
      &::before {
        position: absolute;
        content: '';
        top: 8px;
        left: 4px;
        height: 16px;
        width: 16px;
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='black' class='bi bi-search' viewBox='0 0 16 16'><path fill-rule='evenodd' d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'/></svg>");
      }
      & > table > tbody > tr > .gsib_a {
        padding-left: 2rem;
      }
    }
  }
  .gsc-positioningWrapper {}
  .gsc-refinementsAreaInvisible {}
  .gsc-result {
    background-color: transparent !important;
    &.gsc-webResult {
      padding: 2rem 0;
      border-width: 0 0 1px 0;
      border-style: solid;
      border-color: $gray-300;
      &:hover {
        border-color: $gray-300;
      }
    }
    &-info {
      &-container {}
    }
    .gsc-thumbnail-inside {
      padding: 0;
    }
    .gsc-table-result {
      font-family: $font-family-sans-serif !important;
      padding: 0;
      order: 2;
      margin-bottom: .5rem;
    }
    .gsc-url-top {
      padding: 0;
    }
  }
  .gsc-results {
    width: 100%;
    padding: 0;
    &-wrapper {
      &-nooverlay {}
      &-visible {}
    }
    .gsc-cursor {
      display: flex !important;
      &-box {
        margin: 0 0 1rem 0;
      }
      &-current-page {}
      &-page {
        margin-right: 2px !important;
        font-family: $font-family-sans-serif !important;
        font-weight: 800;
        font-size: 1rem;
        background-color: #f3f2ef !important;
        color: $black !important;
        height: 48px;
        width: 48px;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        &.gsc-cursor-current-page {
          background-color: $red !important;
          color: $white !important;
        }
      }
    }
  }
  .gsc-resultsbox-visible {}
  .gsc-resultsRoot {}
  .gsc-search-box {
    &-tools {}
  }
  
  .gsc-search-button {
    &-v2 {}
  }
  .gsc-tabdActive {}
  .gsc-tabData {}
  .gsc-tabhActive {}
  .gsc-tabHeader {}
  .gsc-table-cell-snippet-close {}
  .gsc-table-cell-thumbnail {}
  .gsc-tabsAreaInvisible {}
  .gsc-thumbnail-left {}
  .gsc-url {
    &-top {
      order: 3;
    }
    &-bottom {}
  }
}
</style>
